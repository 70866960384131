import { OverlayProvider } from "@components/contexts/overlayProvider";
import { ThemeProvider } from "@components/contexts/themeProvider";
import { processEnv } from "@lib/processEnv";
import "@styles/colors.css";
import "@styles/common.css";
import "@styles/dark-mode.css";
import "@styles/emojipickercustom.css";
import "@styles/fonts.css";
import "@styles/index.css";
import "@styles/landing-animation.css";
import "@styles/prism.css";
import "@styles/screen-fixings.css";
import "@styles/screen.css";
import "@styles/toc.css";
import "@styles/emojipickercustom.css";
import "@styles/style-min.css";

import { logAmplitude } from "@utils/amplitude";
import { urlTracker } from "@utils/campaign-tracker";
import { AppProps } from "next/app";
import { useRouter } from "next/router";
import React, { useEffect } from "react";
import SnackbarProvider from "react-simple-snackbar";

const LogAmplitude: React.FC = () => {
    const { pathname } = useRouter();
    React.useEffect(() => {
        if (pathname) logAmplitude(pathname);
    }, [pathname]);
    return null;
};

const App: React.FC<AppProps> = ({ Component, pageProps }) => {
    useEffect(() => {
        const { cmsData } = pageProps || { cmsData: null };
        const { bodyClass } = cmsData || { bodyClass: null };
        const htmlTag = document.querySelector("html") as HTMLElement;
        htmlTag.className = bodyClass ? "casper" : "home";
    });

    useEffect(() => {
        urlTracker();
    }, []);

    return (
        <ThemeProvider {...processEnv.darkMode}>
            <OverlayProvider>
                <SnackbarProvider>
                    <LogAmplitude />
                    <Component {...pageProps} />
                </SnackbarProvider>
            </OverlayProvider>
        </ThemeProvider>
    );
};

export default App;
