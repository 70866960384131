import amplitude from "amplitude-js";

export const logAmplitude = async (pageRoute: string): Promise<void> => {
    const amplitudeAPIKey = process.env.NEXT_PUBLIC_AMPLITUDE_API_KEY;
    if (!amplitudeAPIKey) {
        console.warn("Amplitude key not found");
        return;
    }
    await amplitude.getInstance().init(amplitudeAPIKey, "", { includeFbclid: true, includeGclid: true, includeReferrer: true, includeUtm: true, saveEvents: true });
    amplitude.getInstance().logEvent(pageRoute);
};
